<template>
  <div>
    <van-cell-group>
      <van-cell title="社区"
        :value="hzlist.OrgName" />
      <van-cell title="小区"
        :value="hzlist.Region" />
      <van-cell title="姓名"
        :value="hzlist.Name" />
      <van-cell title="身份证号"
        :value="hzlist.IDCard" />
      <van-cell title="联系方式"
        :value="hzlist.Mobile" />
      <van-cell title="出行时间"
        :value="hzlist.OutTime" />
      <van-cell title="交通工具"
        :value="hzlist.Vehicle" />
      <van-cell title="出行目的"
        :value="hzlist.City" />
      <van-cell title="重点停留地点"
        :value="hzlist.Address" />
      <van-cell title="返回时间"
        :value="hzlist.BackTime" />
      <van-cell title="返回交通工具"
        :value="hzlist.BackVehicle" />
      <van-cell title="是否有同行"
        v-if="hzlist.IsPeer == 1"
        value="是" />
      <van-cell title="是否有同行"
        v-else
        value="否" />
      <van-cell title="是否核酸检测"
        v-if="hzlist.IsCheck == 1"
        value="是" />
      <van-cell title="是否核酸检测"
        v-else
        value="否" />
      <van-cell title="报备时间"
        :value="hzlist.AddTime" />
      <van-cell title="状态"
        :value="hzlist.StatusDesc" />

      <!-- <van-cell title="健康码"
        value="" /> -->
      <!-- <van-image width="100"
        height="100"
        :src="HealthCodeUrl" /> -->
      <!-- <van-uploader v-model="HealthCodeUrl" /> -->

    </van-cell-group>
    <!-- <van-button round
      block
      type="info"
      color="#617bfa"
      style="font-size: 16px"
      @click="SaveZh()">保存</van-button> -->
  </div>
</template>
<script>
import { GetResident } from "@/api/wangge";
import { getwgToken } from "@/utils/auth";
export default {
  data () {
    return {
      listfrom: {},
      hzlist: [],
      url: "https://api.qxzhqm.cn/",
      HealthCodeUrl: [],
    }
  },
  created () {
    this.cyXq();
  },
  methods: {
    // 获取住户列表
    cyXq () {
      console.log(this.$route);
      console.log(JSON.parse(this.$route.params.itemmark));
      this.hzlist = JSON.parse(this.$route.params.itemmark)
      // 健康码{ url: 'https://cdn.jsdelivr.net/npm/@vant/assets/leaf.jpeg' },
      this.HealthCodeUrl = this.url + this.hzlist.HealthCode
      console.log(this.HealthCodeUrl);
      // this.listfrom.hId = this.$route.query.HId;
      // this.listfrom.accToken = getwgToken();
      // GetResident(this.listfrom)
      //   .then((res) => {
      //     if (res.data.code == 0) {
      //       if (res.data.count == 0) {
      //         this.hzlist = {};
      //       } else {
      //         this.hzlist = res.data.data;
      //       }
      //     }
      //   })
      //   .catch(() => { });
    },
  },
}
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
</style>